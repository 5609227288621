<template>
  <div>
    <singlePoster :posterPayload="section" v-if="section && section.displayPoster"></singlePoster>

    <div :dir="localDisplayLang === 'ara' ? 'rtl' : ''" id="grid_container">
      <div v-if="contents.length === 0">
        <ErrorAlert class="error-alert" :message="message"></ErrorAlert>
      </div>
      <div class="container grid_contents" v-else>
        <div v-for="(item, index) in contents" :key="index" @click="clickHandler(item)">
          <categoryCard
            :content="item"
            :displayTags="true"
            :screen="section"
            :isDirectPlay="false"
            :cardType="fetchCardType(item)"
            :id="index"
            @play="playcontent()"
            :playerInstance="playerInstance"
          ></categoryCard>
        </div>
      </div>
      <div v-if="loader">
        <Loading class="loader"></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    contents: {
      type: Array,
    },
    section: {
      type: Object,
    },
    message: {
      type: String,
    },
    isScrollable: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      localDisplayLang: null,
      loader: false,
      scroll: true,
    };
  },
  watch: {
    contents(val) {
      if (val) {
        this.scroll = true;
      }
    },
  },
  created() {
    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    }

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    let self = this;

    //To detect bottom scroll on the window.
    window.addEventListener("scroll", function() {
      var scrollHeight, totalHeight;
      scrollHeight = document.body.scrollHeight;
      totalHeight = window.scrollY + window.innerHeight;

      if (totalHeight >= scrollHeight) {
        if (self.isScrollable && self.scroll) {
          self.loader = true;
          self.$emit("loadMore");
          self.scroll = false;
        }
        else {
        // self.loader = false;
      }
      } else {
        self.loader = false;
      }
      if (!self.isScrollable) {
        self.loader = false;
        self.scroll = false;


      }
    });
  },
  methods: {
    getLandscapse() {
      
      if (screen.width > 425 && screen.width < 760) {
        return { "grid-template-columns": "repeat(4, 1fr)" };
      } else if (screen.width > 425) {
        return { "grid-template-columns": "repeat(4, 1fr)" };
      } else if (screen.width <= 425) {
        return { "grid-template-columns": "repeat(2, 1fr)" };
      } else {
        return { "grid-template-columns": "repeat(2, 1fr)" };
      }
    },
    getPort() {
      if (screen.width > 425 && screen.width < 760) {
        return { "grid-template-columns": "repeat(3, 1fr)" };
      } else if (screen.width > 425 && screen.width < 768) {
        return { "grid-template-columns": "repeat(3, 1fr)" };
      } else if (screen.width > 768 && screen.width <= 1024) {
        return { "grid-template-columns": "repeat(5, 1fr)" };
      } else if (screen.width > 1024) {
        return { "grid-template-columns": "repeat(5, 1fr)" };
      } else if (screen.width <= 425) {
        return { "grid-template-columns": "repeat(3, 1fr)" };
      } else {
        return { "grid-template-columns": "repeat(3, 1fr)" };
      }
    },
    fetchCardType() {
      if (screen.width < 375) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "100px",
              width: "170px",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "150px",
              width: "100px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width >= 375 && screen.width <= 425) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "100px",
              width: "170px",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "163px",
              width: "108px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 425 && screen.width <= 800) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "147px",
              width: "240px",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "270px",
              width: "auto",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 800 && screen.width < 1050) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "120px",
              width: "210px",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "180px",
              width: "auto",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 1050 && screen.width <= 1440) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "175px",
              width: "auto",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "270px",
              width: "180px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 1440 && screen.width < 1920) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "155px",
              width: "270px",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "310px",
              width: "200px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      }
       else if (screen.width >= 1920 && screen.width < 2560) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "155px",
              width: "270px",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              
              height: "270px",
              width: "190px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "175px",
              width: "auto",
              playButton: { enablePlay: false },
            }
          : this.section.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "285px",
              width: "270px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      }
    },
    clickHandler(content) {
   
          if (content.category == "TVSHOW") {
            let title = content.title.replace(/\s/g, "-");
              this.$router.push({ name: "detailPage", params: { contentId: content.objectid , mediaTitle:title  } });
          } else if (content.category == "MOVIE") {
            let title = content.title.replace(/\s/g, '-')
            this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid  } });
          }
     
      
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
    ErrorAlert: () => import(/* webpackChunkName: "erroralert" */ "@/components/Templates/ErrorAlert.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    singlePoster: () => import(/* webpackChunkName: "SinglePosterView" */ "./SinglePosterView.vue"),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {
      this.contents = [];
    });
  },
};
</script>

<style lang="scss" scoped>
// @import "@/sass/_components.scss";
@import "./noorPlayGrid.scss"
</style>
